import React, { FC, useMemo, useState } from "react";

import { BaseButton } from "src/components/Buttons";
import { useCryptoContext } from "src/context/CryptoContext";
import cn from "classnames";
import { CRYPTO_WALLETS, CryptoNames } from "src/constants/crypto";

import { TextField } from "src/components/inputs";
import { createWalletHistory, updateWalletsData } from "src/api/firebase";
import { SearchInput } from "../SearchInput";

import s from "./index.module.scss";
import { CreateWalletUpdateParams } from "src/api/types";

type WalletsEditorProps = {};

export const WalletsEditor: FC<WalletsEditorProps> = ({}) => {
  const [loading, setLoading] = useState(false);

  const { wallets } = useCryptoContext();
  const [localWallets, setLocalWallets] =
    useState<Record<CryptoNames, string>>(wallets);
  const [searchValue, setSearchValue] = useState("");

  const walletsNames = useMemo(() => Object.keys(wallets), [wallets]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalWallets({ ...localWallets, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    if (!localWallets) return;
    setLoading(true);
    const data: CreateWalletUpdateParams["data"] = [];

    for (const key in localWallets) {
      const name = key as CryptoNames;
      if (localWallets[name] !== wallets[name]) {
        data.push({
          crypto: name,
          initial: wallets[name],
          updated: localWallets[name],
        });
      }
    }

    await createWalletHistory({
      updated_at: new Date(Date.now()),
      data,
    });
    await updateWalletsData(localWallets);
    setLoading(false);
  };

  const initWallets = async () => {
    return await updateWalletsData(CRYPTO_WALLETS);
  };

  return (
    <div className={s.wrapper} onClick={(e) => e.stopPropagation()}>
      <h2 className={s.title}>Edit Wallets</h2>
      {/* <button onClick={() => initWallets()}>INIZIALIZE</button> */}
      <SearchInput
        className={s.search}
        onChange={(v) => {
          setSearchValue(v);
        }}
      />
      <ul className={s.list}>
        {walletsNames.map((crypto) => (
          <li key={crypto}>
            <TextField
              className={cn({
                [s.withLighting]:
                  searchValue &&
                  crypto
                    .toLocaleUpperCase()
                    .includes(searchValue.toLocaleUpperCase()),
              })}
              name={crypto}
              label={crypto}
              onChange={handleInputChange}
              value={localWallets[crypto as CryptoNames]}
            />
          </li>
        ))}
      </ul>
      <div className={s.buttonWrapper}>
        <BaseButton
          loading={loading}
          color="success"
          size="md"
          onClick={() => handleSave()}
          className={s.button}
        >
          Save
        </BaseButton>
      </div>
    </div>
  );
};
