import React from "react";

import { useTranslation } from "react-i18next";
import { ErrorIcon } from "src/components/icons";
import { Link, useNavigate } from "react-router-dom";
import { CONTACTS_ROUTE } from "src/routes/dictionary";
import { BaseButton } from "src/components/Buttons";

import s from "./index.module.scss";

function Error() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <section className={s.pattern}>
      <div className={s.wrap}>
        <div className={s.patternComplete}>
          <ErrorIcon />
          <h2 className={s.patternCompleteTitle}>
            {t("An error occurred during the transaction")}
          </h2>
          <div className={s.patternCompleteText}>
            {t(
              "The system could not automatically process the transaction, please contact support immediately."
            )}
          </div>
          <div className={s.patternCompleteText}>
            <BaseButton
              className={s.button}
              onClick={() => navigate(CONTACTS_ROUTE)}
            >
              {t("Contact support")}
            </BaseButton>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Error;
