import cn from "classnames";
import { onSnapshot } from "firebase/firestore";
import React, { FC, useEffect, useState } from "react";
import { userTransactionsCollection } from "src/api/firebase";
import { UserExchangeResponse } from "src/api/types";
import { EditTransactionPopup } from "src/components/Popups/EditTransactionPopup";
import { STATUS_TITLES } from "src/constants/crypto";
import { usePopupContext } from "src/context/PopupContext";
import { formatDate } from "src/utils/string";

import s from "./index.module.scss";

type TransactionsEditorProps = {};

export const TransactionsEditor: FC<TransactionsEditorProps> = () => {
  const { openPopup } = usePopupContext();
  const [loading, setLoading] = useState(false);
  const [userTransactions, setUserTransactions] = useState<
    UserExchangeResponse[]
  >([]);

  useEffect(() => {
    const unsubscribe = onSnapshot(userTransactionsCollection, (snapshot) => {
      const newTransactions: UserExchangeResponse[] = snapshot.docs
        .map((doc) => doc.data() as UserExchangeResponse)
        .filter((transaction) => transaction.status !== "cancelled")
        .sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );

      setUserTransactions(newTransactions);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleTransactionChange = (transaction: UserExchangeResponse) => {
    setUserTransactions((prev) =>
      prev.map((t) =>
        t.transactionId === transaction.transactionId ? transaction : t
      )
    );
  };

  if (loading) return null;

  return (
    <div className={s.wrapper} onClick={(e) => e.stopPropagation()}>
      <h2 className={s.title}>Edit Transactions</h2>
      <div className={s.table}>
        <div>ID</div>
        <div>Дата</div>
        <div>Сумма / Курс</div>
        <div>Данные</div>
        <div className={s.centered}>Статус</div>
        <div></div>

        {userTransactions.map((transaction) => (
          <>
            <div className={s.centered}>{transaction.transactionId}</div>
            <div className={s.centered}>
              {formatDate(transaction.created_at)}
            </div>
            <div className={s.centered}>
              {transaction.transactionValues.amountFrom}{" "}
              {transaction.transactionValues.from.abbreviated}
              {" / "}
              {transaction.transactionValues.from.rate}
            </div>
            <div className={s.details}>
              <span>
                {transaction.transactionValues.from.title} -{" "}
                {transaction.transactionValues.to.title}{" "}
              </span>
              <span>
                <strong>Счёт: </strong>
                {transaction.wallet}
              </span>
              <span>
                <strong>Сумма к выплате: </strong>
                {transaction.transactionValues.amountTo}
              </span>
            </div>
            <div className={s.centered}>
              <div className={cn(s.centered, s.status, s[transaction.status])}>
                {STATUS_TITLES[transaction.status]}
              </div>
            </div>
            <div className={s.centered}>
              <div
                className={s.edit}
                onClick={() => {
                  openPopup(
                    <EditTransactionPopup
                      unSuccess={handleTransactionChange}
                      transaction={transaction}
                    />
                  );
                }}
              >
                Изменить
              </div>
            </div>
          </>
        ))}
      </div>
    </div>
  );
};
