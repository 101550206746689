import { ExchangeStatus } from "src/api/types";

export type CryptoNames =
  | "BTC"
  | "ETH"
  | "LTC"
  | "TRX"
  | "XMR"
  | "ADA"
  | "USDT"
  | "DOGE"
  | "DOT"
  | "SOL"
  | "MATIC"
  | "XTZ"
  | "ZEC"
  | "XLM"
  | "DAI"
  | "BNB"
  | "BCH";

export const CRYPTO: Record<CryptoNames, string> = {
  BTC: "Bitcoin",
  ETH: "Ethereum",
  LTC: "Litecoin",
  TRX: "Tron",
  XMR: "Monero",
  ADA: "Cardano",
  USDT: "Tether TRC20",
  DOGE: "Dogecoin",
  DOT: "Polkadot",
  MATIC: "Polygon",
  SOL: "Solana",
  XTZ: "Tezos",
  ZEC: "ZCash",
  XLM: "Stellar",
  DAI: "Dai",
  BNB: "Binance Coin",
  BCH: "Bitcoin Cash",
};

export const CRYPTO_WALLETS: Record<CryptoNames, string> = {
  BTC: "bc1qpwuz9u6kplrc9f573h6ejg4t48rwvkcsyfprwd",
  ETH: "0x9A9EA38Df132a08DB154955F1B9296229af20fFf",
  LTC: "LZAvSEW5PbYsmrpdctGwe5suBx8FdqBf6E",
  TRX: "TScQyFistxxu5xRU33sJ8BG7qp4E6WcSot",
  XMR: "43e4vF96pkzgwPXFQyyuCF9V76mRHU1nmBEZMG3RuRTCfug2rUU6SMMD4zXjgXsAZxPysw7qw7TCv16cymNomRk88yjaLRr",
  ADA: "addr1qx82t5kt4t37q4p8h2678fj033n7fl6q592rmjr6eynx8ayw5hfvh2hrup2z0w44uwnylrr8unl5pg258hy84jfxv06qwwrjfy",
  USDT: "TPFEe7W9Q3JPe7ZaUXDjesxXqMKohUZ4Sz",
  DOGE: "D8fKc26Mqi6tzBg69jMKEePsWgEcFc4Zsz",
  DOT: "12NYUV8LSCHpP6ze3DsqUaKiAXUdU38zXxBASBhdzJ2mNKvf",
  MATIC: "0x9A9EA38Df132a08DB154955F1B9296229af20fFf",
  SOL: "HJRk4dgRsufTEah3jkqoWzbTzk7Kyk2HHvzhuVYxYWUZ",
  XTZ: "tz1cTCRrsoXP8zWNv7exvakVP4GLc4eRjndc",
  ZEC: "t1cRvMLVxw3dXVXzxCXDGaYBpBXwcoWHzCn",
  XLM: "GACSSFE2AFLXF3AZITSULGYCQQD7YQVWU4XGPHRIWWY3SLXGY6DWYDMY",
  DAI: "0x9A9EA38Df132a08DB154955F1B9296229af20fFf",
  BNB: "bnb1n8t55hajagklf6f4zta3mcp86jwtht949x8hjj",
  BCH: "qq3utraxh2zf2z34zpjnclm7axahupaz8y39s87r7y",
};

export const MIN_USTD_TO_EXCHANGE = 25;

export const CRYPTO_NAMES_LIST = Object.keys(CRYPTO) as CryptoNames[];

export const INITIAL_RESERVE: Record<CryptoNames, number> = {
  BTC: 72,
  ETH: 235,
  LTC: 1908,
  TRX: 9530167,
  XMR: 476.96,
  ADA: 990215,
  USDT: 378413,
  DOGE: 990994,
  DOT: 9670,
  MATIC: 9454,
  SOL: 98980,
  XTZ: 910814,
  ZEC: 9145,
  XLM: 996506,
  DAI: 78613,
  BNB: 4189,
  BCH: 6531,
};

export const ROUND_UP_BY_CURRENCY_NAME: Record<CryptoNames, number> = {
  BTC: 8,
  ETH: 8,
  LTC: 8,
  TRX: 8,
  XMR: 8,
  ADA: 8,
  USDT: 2,
  DOGE: 8,
  DOT: 8,
  MATIC: 8,
  SOL: 8,
  XTZ: 8,
  ZEC: 8,
  XLM: 8,
  DAI: 8,
  BNB: 8,
  BCH: 8,
};

export const MIN_AMOUNTS: Record<CryptoNames, number> = {
  BTC: 0.0008572,
  ETH: 0.013605,
  LTC: 0.31675,
  TRX: 322.25,
  XMR: 0.15725,
  ADA: 84.8,
  USDT: 25,
  DOGE: 336.25,
  DOT: 4.98,
  MATIC: 37.175,
  SOL: 1.0828,
  XTZ: 30.875,
  ZEC: 0.8435,
  XLM: 177.05,
  DAI: 24.99,
  BNB: 0.103125,
  BCH: 0.109875,
};

export const SVG_CRYPTO_AND_FIAT = {
  BTC: "https://coinchanger.in/img/BTC.svg?v=1666338993",
  ETH: "https://coinchanger.in/img/ETH.svg?v=1666338993",
  LTC: "https://coinchanger.in/img/LTC.svg?v=1666338993",
  TRX: "https://coinchanger.in/img/TRX.svg?v=1666338993",
  XMR: "https://coinchanger.in/img/XMR.svg?v=1666338993",
  ADA: "https://coinchanger.in/img/ADA.svg?v=1666338993",
  USDT: "https://coinchanger.in/img/USDTTRC20.svg?v=1666338993",
  DOGE: "https://coinchanger.in/img/DOGE.svg?v=1666338993",
  DOT: "https://coinchanger.in/img/DOT.svg?v=1666338993",
  MATIC: "https://coinchanger.in/img/MATIC.svg?v=1666338993",
  SOL: "https://cryptologos.cc/logos/solana-sol-logo.svg?v=025",
  XTZ: "https://easybit.com/images/coins/svg/XTZ.svg",
  ZEC: "https://coinchanger.in/img/ZEC.svg?v=1666338993",
  XLM: "https://coinchanger.in/img/XLM.svg?v=1666338993",
  DAI: "https://coinchanger.in/img/DAI.svg?v=1666338993",
  BNB: "https://easybit.com/images/coins/svg/BNB.svg",
  BCH: "https://coinchanger.in/img/BCH.svg?v=1666338993",
};

export const STATUSES: ExchangeStatus[] = [
  "error",
  "payed",
  "pending",
  "success",
];

export const STATUS_TITLES: Partial<Record<ExchangeStatus, string>> = {
  error: "Ошибка",
  payed: "Оплачено",
  pending: "Ожидает оплаты",
  success: "Исполнено",
};
